import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import { useIntl, Link } from "gatsby-plugin-intl";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";

import Layout from "../components/Layout";
import Button from "../components/Button";
import Shape from "../images/shape-2.inline.svg";

const Title = styled.h2`
  font-weight: 500;
  a {
    color: inherit;
  }
`;

const BlogItem = styled.div`
  .image-frame {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: solid 0px #fff;
      transition: all 0.3s ease-in-out;
    }
    &:hover:after {
      border-width: 10px;
    }
  }
`;

const PaginationItem = styled(Pagination.Item)`
  color: ${props => props.theme.colors.gray};
  line-height: 1;
  padding: 0 8px;
  a,
  span {
    border: none;
    padding: 0;
  }
  &.page-item.active {
    span {
      background-color: transparent;
      color: #000;
      line-height: 1;
      &:before {
        content: "(";
      }
      &:after {
        content: ")";
      }
    }
  }
`;

const LeftShape = styled(Parallax)`
  position: absolute;
  bottom: 200px;
  margin: auto;
  left: 25px;
  width: 150px;
  display: flex;
  align-items: center;
  svg {
    width: 100%;
    height: auto;
  }
`;

const PaginationEllipsis = styled(Pagination.Ellipsis)`
  span {
    color: ${props => props.theme.colors.gray};
  }
  a {
    border: none;
    padding: 0;
  }
`;

const Blog = ({ data, pageContext }) => {
  const { allPrismicBlog } = data;
  const { numPages, currentPage } = pageContext;
  const intl = useIntl();

  return (
    <Layout>
      <LeftShape y={[20, -200]}>
        <Shape />
      </LeftShape>
      <Container fluid className="py-5 my-5">
        <Row>
          {allPrismicBlog.nodes.map(post => {
            const img = getImage(post.data.cover.localFile.childImageSharp);
            return (
              <Col key={post.uid} md={6} lg={4} className="mb-5 pb-4">
                <BlogItem>
                  <Link to={`/blog/${post.uid}`}>
                    <div className="mb-5 image-frame">
                      <GatsbyImage
                        image={img}
                        alt={post.data.cover.alt || ""}
                      />
                    </div>
                  </Link>
                  <Title className="text-uppercase mb-5">
                    <Link to={`/blog/${post.uid}`}>{post.data.title.text}</Link>
                  </Title>
                  <div className="mb-5">
                    <RichText render={post.data.short_description.raw} />
                  </div>
                  <Button to={`/blog/${post.uid}`}>
                    {intl.formatMessage({ id: "view_more" })}
                  </Button>
                </BlogItem>
              </Col>
            );
          })}
        </Row>
        {numPages > 1 && (
          <Pagination className="justify-content-center">
            {currentPage > 3 && (
              <>
                <PaginationItem as={Link} to="/blog">
                  {1}
                </PaginationItem>

                {currentPage > 4 && <PaginationEllipsis />}
                <PaginationItem as={Link} to={`/blog/${currentPage - 2}`}>
                  {currentPage - 2}
                </PaginationItem>
                <PaginationItem as={Link} to={`/blog/${currentPage - 1}`}>
                  {currentPage - 1}
                </PaginationItem>
              </>
            )}
            <PaginationItem active>{currentPage}</PaginationItem>
            {currentPage < numPages && (
              <>
                {numPages - currentPage > 2 ? (
                  <>
                    <PaginationItem as={Link} to={`/blog/${currentPage + 1}`}>
                      {currentPage + 1}
                    </PaginationItem>
                    <PaginationItem as={Link} to={`/blog/${currentPage + 2}`}>
                      {currentPage + 2}
                    </PaginationItem>
                    <Pagination.Ellipsis />
                    <PaginationItem as={Link} to={`/blog/${numPages}`}>
                      {numPages}
                    </PaginationItem>
                  </>
                ) : (
                  <>
                    <PaginationItem as={Link} to={`/blog/${currentPage + 1}`}>
                      {currentPage + 1}
                    </PaginationItem>
                    <PaginationItem as={Link} to={`/blog/${currentPage + 2}`}>
                      {currentPage + 2}
                    </PaginationItem>
                  </>
                )}
              </>
            )}
          </Pagination>
        )}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($limit: Int!, $skip: Int!, $language: String!) {
    allPrismicBlog(
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $language } }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          cover {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(width: 800, height: 480)
              }
            }
          }
          short_description {
            raw
          }
        }
      }
    }
  }
`;

export default Blog;
